// Input Rules


// list-item 
.list-client-item, .list-choice-collab {
  background: transparent;
  border: none;
  width: calc(100% - .5rem);
  color: $white;
  padding: $padding-box;
  margin: .25rem;
  border-radius: $radius-box;
  display: flex;
  align-items: center;
  gap: 20px;
  border: $border-box-hover;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus,
  &.active {
    color: $white;
    background-image: linear-gradient(rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)),
    $color-box;
    background-origin: border-box;
    background-clip: content-box,
    border-box;
    box-shadow: $border 1000px $border $black inset;
    background-size: 200% 200%;
    animation: AnimationBkg 4s ease infinite;
  }
  
}

.list-choice-collab {
  color: $black;

  &:hover {
    color: $white;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  padding: $space-md 0 $space-md 0;

  label {
    margin: 0;
    z-index: 10;
    cursor: pointer;
  }

  input {
    margin-right: $space-md;
  }
}

// Input Form
.input {
  border-radius: $radius-box;
  font-family: #{$font-text};
  font-size: #{$text-sm};
  margin-top: $space-md;
  border: $border-box;
  background-color: transparent;

  *:not(span) {
    position: relative;
    display: inherit;
    border-radius: inherit;
    width: -webkit-fill-available;
    margin: 0;
    border: $border-box-hover;
    outline: none;
    padding: $padding-box;
    z-index: 1;
  }

  &:hover,
  &:active,
  &:focus,
  &:visited {
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: $border-box-hover;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      $color-box;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: $border 1000px $border transparent inset;
    background-size: 200% 200%;
    animation: AnimationBkg 4s ease infinite;
  }

  &.disabled {
    &:hover,
    &:active,
    &:focus,
    &:visited {
      border: $border-box;
      background-image: none;
      animation: none;
    }
  }

  @keyframes AnimationBkg {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}

input[type='file']{
  display :none;
}

.input-info {
  border-radius: $radius-box;
  font-family: #{$font-text};
  font-size: #{$text-sm};
  padding: $padding-box;
  margin-top: $space-md;
  border: $border-button;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  text-align: center;
}

.input-info-black{
  margin-top: 0;
  background-color: $black;
  color: $white;
}

.input-info-grey{
  height:100%;
  border-radius: $radius-card;
  font-family: #{$font-text};
  font-size: #{$text-sm};
  padding: $padding-box;
  background-color: $greyUi;
}


