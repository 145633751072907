// Sidebar rules


// Sidebar global
.sidebar {
  color: $color-text;
  background: $greyUi;
  padding: $space-md;
  margin-right: $space-md;
  height: fit-content;
  position: relative;
  box-sizing: border-box;
  color: $color-text;
  border-radius: $radius-card;

  .sidebar-content {
    .title-sidebar {
      padding: 0 $space-md $space-sm $space-md;
      display: flex;
      align-items: center;
      h5 {
        padding: $space-md $space-md $space-sm $space-md;
        margin: 0;
      }
    }
    .btn-sidebar {
      margin: 0.2rem 0;
      border: none;
      width: 100%;
      text-align: -webkit-left;
      padding: 0.75rem 0.8rem;
    }
    
  }
}

// Sidebar funnel
.sidebar-funnel {
  align-items: center;
  justify-content: center;
  padding-right: $space-md;
  height: fit-content;
  position: relative;
  box-sizing: border-box;

  .sidebar-content {
    .step {
      border-radius: $radius-card;
      padding: $padding-box;
      margin: .25rem 0;

      &.active {
        background: $greyUi;
      }
      .box-sidebar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          margin-bottom: 0;
          margin-right: 1rem;
        }
        .title-sidebar {
          padding: $space-sm 0 $space-sm 0;
          display: flex;
          align-items: center;
          .btn-no-style, .h5 {
            margin-left: 0.6rem;
            margin-bottom: 0;
          }
        }
      }
      .btn-sidebar {
        border: none;
        width: 100%;
        text-align: -webkit-left;
        border-radius: $radius-box;
        font-family: #{$font-text};
        font-size: #{$text-sm};
        padding: 0.75rem 0;
        background-color: transparent;
        cursor: initial;
      }
    }
  }

}

// Sidebar orders 


.sidebar-orders{
  align-items: center;
  justify-content: center;
  padding-right: $space-md;
  height: fit-content;
  position: relative;
  box-sizing: border-box;
    .orders{
      padding: $padding-box;
      margin: .25rem 0;
      background-color: $greyUi;
      border-radius: $radius-card;
      h5 {
        margin-bottom: 0;
      }
      .orders-info{
        display: flex;
        align-items: center;
        gap: .5rem;
      }
      .orders-details{
        display: flex;
        align-items: center;
        .circle{
          border: $border-button;
          border-radius: 100px;
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 1rem;
        }
        .info-transport{
          font-size: $text-sm; 
        }
      }
    }
}


@media screen and (max-width: $lg) {
  .sidebar {
    display: none;
  }
  .sidebar-orders{
    padding-right: 0;
  }
}
