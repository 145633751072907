// Table Rules
.table-container{
  margin-right: auto;
  margin-left: auto;
  box-shadow: inherit !important;
  overflow-x: auto;

  .table-content {
    font-family: $font-text;
    font-size: $text-sm !important;
    background-color: $white;
    border-color: transparent;
    .table-head {
      th{
        overflow-x: auto;
        white-space: nowrap;
      }
      .table-row {
        .table-cell {
          font-family: $font-heading !important;
        }
      }
    }

    .table-body {
      td {
          overflow-x: auto;
          white-space: nowrap;
        }
      .table-row {
        &:hover{
          // padding: $padding-box;
          background-color: $greyUi;
        }
        .table-cell {
          font-family: $font-text;
          font-size: $text-sm;
          border-bottom: 0;
          h5{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// Search bar
.search-bar {
  .input {
    margin: 0;
  }
  button {
    margin: 0;
  }
  .btn {
    width:100%;
  }
}

// Tables infos
.flex-img-fullname {
  display: flex;
  align-items: center;
  gap: 24px;

  img.avatar-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: block;
    margin: 1rem 0;
  }

  p {
    font-family: $font-heading;
  }
}

.flex-btn-bordereau {
  display: flex;
  align-items: center;
  &:hover, 
  &:focus, 
  &:active{
    text-decoration: underline;
  }
  svg{
    margin-right: 1rem;
  }
}

.cell-clickable ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.8rem;
}


.toolbarContact, .toolbarBtn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  width: 100%;
}

.toolbarContact{
  padding-bottom: 1rem;
  border-top: 1px solid #00000029;
  border-bottom: 1px solid #00000029;
}

.toolbarBtn{
  gap: 2rem;
}

@media screen and (max-width: $lg) {
  .toolbarContact, .toolbarBtn{
    display:block;
  }

  .table-container {
    overflow-x: auto;
  }
}