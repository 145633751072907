// Brand rules

/* nav-brand */
.brand {
  display: flex;
  align-items: center;
  justify-content: center;

  .brand-svg {
    position: absolute;
    background-image: url('/assets/img/huub-logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    width: 4.8rem;
    height: 4.8rem;
  }

  .brand-logo {
    position: sticky;

    .box-logo {
      padding: 2.4rem;
      align-items: center;
      justify-content: center;
      margin: auto;
      position: relative;
      box-sizing: border-box;
      color: $color-text;
      background: $white;
      background-clip: padding-box;
      border: $border-box-hover;
      border-radius: 0.6rem;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background-size: 200% 200%;
        animation: AnimationBkg 4s ease infinite;
        background-image: $color-box;
        background-repeat: repeat;
      }

      @keyframes AnimationBkg {
        0% {
          background-position: 0% 50%;
        }

        50% {
          background-position: 100% 50%;
        }

        100% {
          background-position: 0% 50%;
        }
      }
    }
  }
}

.nav-brand {
  .brand {
    .brand-svg {
      width: 3.8rem;
      height: 3.8rem;
    }

    .brand-logo {
      .box-logo {
        padding: 1.8rem;
        background: $greyUi !important;
        background-clip: padding-box !important;
      }
    }
  }
}
