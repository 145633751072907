// Modal Rules

.card {
  padding: 1rem;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  color: $color-text;
  background: $white;
  background-clip: padding-box;
  border: $border-box-hover;
  border-radius: $radius-card;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border;
    border-radius: inherit;
    background: $color-box;
  }

}
.card-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 50%;
  min-height: 50%;
  background: none;
  :focus-visible{
    outline: transparent;
    outline-width: 0 !important;
  }
}

.card-form,
.card-info {
  margin: #{$space-md} 0;
}

@media screen and (max-width: $lg) {

  .card-modal{
    
      .card{
        overflow-y: auto;
        width: 50rem;
        max-width: calc(100vw - 2rem);
        max-height: calc(100vh - 2rem);
        &:before{
          display: none;
        }
      }
  }
}
