// Navbar Rules
header{
  box-sizing: border-box;
  left: 0px;
  padding: 0px;
  top: 0px;
  transition: transform 0.3s ease 0s;
  width: 100%;
  z-index: 1600;
  position: sticky;
}
nav {
  z-index: 1600;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  background-color: $greyUi;
  padding: $space-md 0;
  border-bottom: $border-table;

  div [class*='navbar-'] {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-links {
    height: 100%;
    width: 86%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    li {
      list-style: none;
    }

    .nav-menu-item {
      font-family: #{$font-heading};
      color: #{$color-text};
      text-decoration: none;
      align-items: center;

      em {
        font-size: #{$text-sm};
        font-style: normal;
        color: $color-text;
        padding: 1px;
        svg {
          font-size: 14px;;
          margin-right: 0.5rem;
        }
      }

      &:hover,
      &:active,
      &:focus,
      &.active {
        em {
          background-image: $color-box;
          background-repeat: repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
          background-size: 200% 200%;
          animation: AnimationBkg 4s ease infinite;
        }

        svg {
          color: $pink;
        }
        

        @keyframes AnimationBkg {
          0% {
            background-position: 0% 50%;
          }

          50% {
            background-position: 100% 50%;
          }

          100% {
            background-position: 0% 50%;
          }
        }
      }
    }
  }
}

#nav-toggle {
  position: absolute;
  top: -4rem;
}

nav .icon-burger {
  display: none;
  .line {
    width: 20px;
    height: 3px;
    background-color: $pink;
    margin: 7px;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: $lg) {
  nav {
    .nav-brand {
      float: none;
      width: auto;
      justify-content: center;
      margin-left: 8%;
    }

    .nav-links {
      position: fixed;
      z-index: 9;
      left: 0;
      right: 0;
      top: 5rem;
      bottom: 100%;
      padding: 0 4rem;
      width: auto;
      height: auto;
      flex-direction: column;
      background-color: $greyUi;
      overflow: hidden;
      box-sizing: border-box;
      transition: all 0.5s ease-in-out;

      .nav-menu-item em{
        font-size: initial;
      }
    }
  }

  nav :checked ~ .nav-links {
    bottom: 0;
  }

  nav .icon-burger {
    display: block;
  }

  nav :checked ~ .icon-burger .line:nth-child(1) {
    transform: translateY(10px) rotate(225deg);
  }

  nav :checked ~ .icon-burger .line:nth-child(3) {
    transform: translateY(-10px) rotate(-225deg);
  }

  nav :checked ~ .icon-burger .line:nth-child(2) {
    opacity: 0;
  }
}

.breadcrumps {
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
  }

  h5 {
    margin-bottom: 0;
    margin-top: 2px;
  }
}

.breadcrumps-step p {
  margin: 0 !important;
  padding: 0 !important;
}

