// Background rules

.bkg-section {
    min-height: 100vh;
    background: $black;
    display: flex;
    position: relative;
    overflow: hidden;
}

.bkg-effect {
    width: 700px;
    margin-left: -350px;
    margin-top: -350px;
    height: 700px;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    position: absolute;
    background: $shad-bkg;
    filter: blur(100px);
    transform: translateZ(0px);
    background-size: 200% 200%;
    animation: AnimationBkg 4s linear  infinite;
}

@keyframes AnimationBkg {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}