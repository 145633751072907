// Login Rules

// .section {
//     width: 100vw;
//     height: 100vh;
// }

// /* Clear floats after the columns */
// .section:after {
//     content: "";
//     display: table;
//     clear: both;
// }

.section-login,
.section-404 {
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.section-login {
  form {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.section-404 {
  align-items: center;
  text-align: center;
  h1 {
    font-size: 26rem;
    line-height: 26rem;
    padding-top: $space-md;
    margin: 0;
  }
}

@media screen and (max-width: $lg) {
  .box-404 {
    max-width: 540px;
    h1 {
      font-size: 16rem;
      line-height: 16rem;
    }
  }
  //   .section-login {
  //     form {
  //       padding-right: 0;
  //       padding-left: 0;
  //     }
  //   }
}


