// Rules Statuts
input.btn-statuts{
  display: none;
}


.box-statuts {
  border-radius: $radius-box;
  background-color: rgba(255, 145, 0, 0.2);
  padding: $padding-box-info;
  color: $color-process;
  .box-pending {
    background-color: rgba(255, 145, 0, 0.2);
    color: $color-process;
  }
  .box-confirm {
    background-color: rgba(255, 0, 180, 0.2);
    color: $color-confirm;
  }
  .box-progress {
    background-color: rgba(0, 189, 255, 0.2);
    color: $color-progress;
  }
  .box-waiting {
    background-color: rgba(255, 0, 0, 0.2);
    color: $color-await;
  }
  .box-delivery {
    background-color: rgba(41, 255, 0, 0.2);
    color: $color-success;
  }
}

.btn-statuts {
  text-align: center;
  border-radius: $radius-box;
  padding: $padding-box-info;
  border: none;
  font-family: #{$font-heading};
  font-size: #{$text-sm};
  width: max-content;
  &:hover,
  &:active,
  &:focus,
  &.active {
    opacity: .8;
  }

  &:hover {
    cursor: pointer;
  }
}

.box-pending {
  background-color: rgba(255, 145, 0, 0.2);
  color: $color-process;
  &:checked + label {
    border: 2px solid rgba(255, 145, 0, 0.5);
  }
}

.box-progress, .box-confirm {
  background-color: rgba(0, 189, 255, 0.2);
  color: $color-progress;
  &:checked + label {
    border: 2px solid rgba(0, 189, 255, 0.5);
  }
}

.box-confirm {
  background-color: rgba(255, 0, 180, 0.2);
  color: $color-confirm;
  &:checked + label {
    border: 2px solid rgba(255, 0, 180, 0.5);
  }
}

.box-waiting {
  background-color: rgba(255, 0, 0, 0.2);
  color: $color-await;
  &:checked + label {
    border: 2px solid rgba(255, 0, 0, 0.5);
  }
}

.box-delivery {
  background-color: rgba(41, 255, 0, 0.2);
  color: $color-success;
  &:checked + label {
    border: 2px solid rgba(41, 255, 0, 0.5);
  }
}


