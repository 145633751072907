// Colors Rules

$black: #0b0421;
$greyUi: #f6f6f8;
$grey: #00000029;
$white: #ffffff;
$purple: #7d58c8;
$blue: #00bdff;
$aqua: #75d498;
$green: #188f00;
$orange: #ff9100;
$red: #ff0000;
$pink: #FF00B4;
$shad: linear-gradient(
  135deg,
  rgb(206, 85, 176) 0.52%,
  rgb(243, 77, 172) 14.81%,
  rgb(244, 142, 102) 29.09%,
  rgb(240, 184, 50) 43.38%,
  rgb(178, 206, 55) 57.66%,
  rgb(99, 215, 175) 71.95%,
  rgb(28, 194, 223) 86.24%,
  rgb(5, 128, 230) 100.52%
);
$shad-bkg: radial-gradient(
    90.53% 97.67% at 8.89% 16.06%,
    rgb(206, 85, 176) 0%,
    rgb(243, 77, 172) 16.56%,
    rgb(244, 142, 102) 34.71%,
    rgb(240, 184, 50) 50.87%,
    rgb(99, 215, 175) 60.96%,
    rgb(28, 194, 223) 74.2%,
    rgb(5, 128, 230) 87.06%
  )
  center center;
