// Typography Rules

$font-text: 'Gordita Regular', sans-serif;
$font-heading: 'Gordita Medium', sans-serif;

/* set base values */
$text-base-size: 1em; // ( =16px )
$text-scale-ratio: 1.25;

/* type scale */
$text-xs: calc(1rem / ($text-scale-ratio * $text-scale-ratio));
$text-sm: calc(1rem / $text-scale-ratio);
$text-md: calc(1rem * $text-scale-ratio);
$text-lg: calc(1rem * $text-scale-ratio * $text-scale-ratio);
$text-xl: calc(
  1rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio
);
$text-xxl: calc(
  1rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio *
    $text-scale-ratio
);
$text-xxxl: calc(
  1rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio *
    $text-scale-ratio * $text-scale-ratio
);
@media screen and (max-width: $md) {
  $text-xs: calc(0.8rem / ($text-scale-ratio * $text-scale-ratio));
  $text-sm: calc(0 / 8rem / $text-scale-ratio);
  $text-md: calc(0.8rem * $text-scale-ratio);
  $text-lg: calc(0.8rem * $text-scale-ratio * $text-scale-ratio);
  $text-xl: calc(
    0.8rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio
  );
  $text-xxl: calc(
    0.8rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio *
      $text-scale-ratio
  );
  $text-xxxl: calc(
    0.8rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio *
      $text-scale-ratio * $text-scale-ratio
  );
}
/* line-height */
$heading-line-height: 1.6;
$body-line-height: 1.4;
$link-line-height: 0;

// /* responsive type */
// $minScreen: 36rem;
// $maxScreen: 50rem;
// $minFont: 0.8rem;
// $maxFont: 2rem;

/* typography styles */

body {
  font-size: $text-base-size;
  font-family: #{$font-text};
  line-height: $body-line-height;
  color: #{$color-text};
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: #{$font-heading};
    line-height: $heading-line-height;
  }
}

h1,
.h1 {
  font-size: $text-xxl;
  line-height: $text-xxl;
  margin-bottom: #{$space-xxl};
}

h2,
.h2 {
  font-size: $text-xl;
  line-height: $text-xl;
  margin-bottom: #{$space-xl};
}

h3,
.h3 {
  font-size: $text-lg;
  line-height: $text-lg;
  margin-bottom: #{$space-lg};
}

h4,
.h4 {
  font-size: $text-md;
  line-height: $text-md;
  margin-bottom: #{$space-md};
}

h5,
.h5 {
  font-size: $text-base-size;
  line-height: $body-line-height;
  margin-bottom: #{$space-md};
}

.small {
  font-family: #{$font-text};
  font-size: #{$text-xs};
  // margin-left: #{$space-md};
  // margin-bottom: #{$space-sm};
  line-height: initial;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

a,
em,
textarea,
label,
select {
  font-style: normal !important;
  font-size: #{$text-sm};
  line-height: #{$link-line-height};
  color: #{$color-text};
  text-decoration: none;
}

.tracking-link {
  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: $lg) {
  a,
  em,
  textarea,
  label,
  select {
    font-style: normal !important;
    font-size: #{$text-xs};
    span{
      font-size: #{$text-xs} !important;
    }
  }
}

