// Dashboard Rules

.section-dashboard {
  text-align: center;

  h4 {
    padding-top: $space-xxxl;
    span {
      color: $color-process !important;
    }
  }

  .info-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: $space-xxxl;
    gap: $space-lg;
    border-bottom: $border-table;
  }

  .stats-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $space-xxxl 0;
    gap: $space-lg;

    .card {
      padding: $space-xxl $space-xxxl;
    }
  }
}

@media screen and (max-width: $lg) {
  .section-dashboard {
    .info-dashboard {
      display:block;
      flex-direction: column;
      padding: 0;

    }

    .stats-dashboard {
      flex-direction: column;
      div {
        width: -webkit-fill-available;
      }
    }
  }
}


