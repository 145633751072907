// Compte Rules

.content-account {
  display: flex;
  justify-content: space-between;
  gap: $space-xl;
  width: 100%;
  height: 100%;
  .MuiCardActions-root {
    justify-content: center;
  }
}

