// Container Rules

/* container style */
.container {
  margin: 0 auto;
  padding-right: 1em;
  padding-left: 1em;
  // width: 100%;
  height: 100%;
  display: block;

  @include mq(sm) {
    max-width: $col-sm;
  }

  @include mq(md) {
    max-width: $col-md;
  }

  @include mq(lg) {
    max-width: $col-lg;
  }

  @include mq(xl) {
    max-width: $col-xl;
  }

  @include mq(xxl) {
    max-width: $col-xxl;
  }

  @include mq(xxxl) {
    max-width: $col-xxxl;
  }
}

/* section global style */
.section {
  top: 0;
  left: 0;
  width: 100vw;
  // height: 100vh;
  // overflow: hidden;
}

div [class*='section-'] {
  padding-top: $space-lg;
  padding-bottom: $space-lg;
  // padding-top: 88px;
}
