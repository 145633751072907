// Form Rules

form{
        display: flex;
        flex-direction: column;
        gap:$space-sm
}

.info {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-size: #{$text-sm};
        text-align:justify;
        background: $shad;
        padding: $padding-box;
        border-radius: $radius-box;
        margin-top: $space-md;
        margin-bottom: $space-md;
        opacity: 0.8;
        background-size: 200% 200%;
        animation: AnimationBkg 8s ease infinite;
        svg{
          width: 60px;
          height: 60px;
        }
        p{
          text-align: center;
          font-size: #{$text-sm};
        }
        .small {
          color: $color-text;
          opacity: 1;
          margin-bottom:0;
        }
        @keyframes AnimationBkg {
          0% {
            background-position: 0% 50%;
          }
      
          50% {
            background-position: 100% 50%;
          }
      
          100% {
            background-position: 0% 50%;
          }
        }
}

@media screen and (max-width: $lg) {
  .info{
        padding:0;
        background: none;
  }
}