@charset 'utf-8';
@use 'sass:meta';

//Abstracts
@import 'abstracts/__abstracts-dir';

//Base Styles
@import 'base/__base-dir';

//Components
@import 'components/__components-dir';

//Layout
@import 'layouts/__layouts-dir';

*:focus-visible {
  outline: none;
}

html {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  // @include fluid-type(font-size, $minScreen, $maxScreen, $minFont, $maxFont);

  body {
    margin: 0;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  *::before,
  *::after {
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
  }
}


