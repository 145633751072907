// Import Fonts

    @font-face {
        font-family: 'Gordita Regular';
        src: url('/assets/fonts/Gordita Regular.woff') format('woff');
    }


    @font-face {
        font-family: 'Gordita Medium';
        src: url('/assets/fonts/Gordita Medium.woff') format('woff');
    }