//Rules funnels
.section-funnel {
  padding: inherit !important;
  display: flex;
  position: fixed;
  flex-grow: 1;
  align-items: center;
  width: 100vw;
  height: 100%;
  animation: 'funnel-section-enter' 250ms ease-out;

  @keyframes funnel-section-enter {
    0% {
      transform: translateX(-15px);
    }

    100% {
      transform: translateX(0);
    }
  }

  .choices-container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .funnel-card {
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $greyUi;
    text-align: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    transition: filter 80ms ease-out, margin 100ms ease-out,
      padding 100ms ease-out;
    animation: 'funnel-card-enter' 150ms ease-out both;

    #cotation {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    #messagerie {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    img{
      width: 50%;
      max-width: 500px
    }
    p{
      font-size: 1rem;
    }
  }

  h3,
  h4 {
    animation: 'funnel-card-enter' 150ms ease-out both;
    animation-delay: 0.2s;
  }

  @keyframes funnel-card-enter {
    from {
      transform: translateX(-15px);
      opacity: 0;
    }

    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }

  .funnel-card.btn-card {
    padding: 1rem;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: $white;
      background-color: $black;
    }
  }
}

//Card funnel
.card-funnel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  height: 100%;
  width: 100%;
}
.card-funnel-validation{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.clientName {
  text-align: center;
  h5 {
    margin-bottom: $space-sm;
  }
  p {
    margin-top: $space-sm;
  }
}

.search-exp {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  .input {
    margin-top: 0;
    margin-right: 1rem;
  }
}

.recap-step {
  .card{
    font-size: #{$text-sm};
    p{
      display:flex
    }
    .table{
      overflow-x: auto;
      border-color: $grey;
      width: 100%;
      margin-bottom: 1.5rem;
      table{
        width:100%;
        thead {
          th {
            overflow-x: auto;
            white-space: nowrap;
            text-align: center;
            border-bottom: 1px solid $grey !important;
            padding: 1rem;
            h5{
              margin: 0;
            }
          }
        }
        tbody{
          td{
            overflow-x: auto;
            white-space: nowrap;
            text-align: center;
            border-bottom: 1px solid $grey !important;
            padding: 1rem;
          }
        }
      }
    }
    .tableRecap{
      overflow-x: auto;
      gap: 2rem;
      padding: 1rem;
      border-top: 1px solid $black;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: $lg) {
  .section-funnel {
    position: absolute;
    .choices-container {
      flex-direction: column;
      .funnel-card {
        img{
          width: 30%;
          max-width: 500px
        }
        p{
          font-size: 1rem;
        }
      }
    }
  }

  .sidebar-funnel {
    display:none;
  }

  .breadcrumps-step {
    font-size: $text-sm !important;
  }

  .card-funnel{
    display: contents;
    min-height: auto;

    &.card{
      padding: 0;
    
      &:before{
        margin:0;
        position: relative;
        background: none;
      }
    } 
  }

  .recap-step{
    .table{
      font-size: 0.6rem;
    }
    .tableRecap{
      img{
        width: 30%;
      }
      p{
        font-size: 0.6rem;
      }
    }
  }
  
}


