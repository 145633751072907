// Variables Rules

$fa-font-path: '../vendors';

@media screen and (max-width: $md) {
  $baseline: 0.2rem;
  $space-sm: calc($baseline/2);
  $space-md: $baseline;
  $space-lg: calc($baseline * 2);
  $space-xl: calc($baseline * 3);
  $space-xxl: calc($baseline * 4);
  $space-xxxl: calc($baseline * 5);
}
// Spacing
$baseline: 0.8rem;
$space-sm: calc($baseline/2);
$space-md: $baseline;
$space-lg: calc($baseline * 2);
$space-xl: calc($baseline * 3);
$space-xxl: calc($baseline * 4);
$space-xxxl: calc($baseline * 5);

// Box
/* border */
$border: 2px;
$border-table: 1px solid $greyUi;
$border-button: 2px solid $black;
$border-box: 1.5px solid $grey;
$border-box-hover: 2px solid transparent;

/* padding */
$padding-box: 0.75rem 1.25rem;
$padding-box-info: 0.4rem 1rem;
$padding-toggle: 2rem 1.5rem;

/*radius */
$radius-box: 46px;
$radius-card: 26px;

// Colors
$color-text: $black;
$color-bkg: $white;

/* links */
$color-box: $shad;
$color-link: $purple;

/* forms */
$color-bkg-check: $black;
$color-checkbox: $greyUi;

/* statues */
$color-process: $orange;
.procress {
  color: $orange;
}
$color-confirm: $pink;
.confirm{
  color: $pink;
}
$color-progress: $blue;
.progress {
  color: $blue;
}
$color-await: $red;
.await {
  color: $color-await;
}
$color-error: $red;
.error {
  color: $red;
}
$color-success: $green;
.success {
  color: $green;
}
