// Button Rules

button {
  cursor: pointer;
}

button.link {
  background: none;
  border: none;
}

button.cell-clickable {
  width: 100%;
  height: 100%;
  padding: 0;
  background: none;
  border: none;
  text-align: left;
}

//Breadcrumps
.breadcrumps{
  background: none !important;
  border: none !important;
  margin: 0;
  padding: 0;
}

.link {
  font-family: #{$font-text};
  font-size: #{$text-xs};
  margin-left: #{$space-md};
  margin-bottom: #{$space-sm};
  color: $blue;
  &:hover,
  &:active,
  &:focus,
  &.active {
    font-family: #{$font-heading};
  }
}

// btn classic
.btn {
  border-radius: $radius-box;
  font-family: #{$font-text};
  font-size: #{$text-sm};
  padding: $padding-box;
  border: $border-button;
  background-color: transparent;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus,
  &.active {
    font-family: #{$font-text};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: $border-box-hover;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      $color-box;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: $border 1000px $border $black inset;
    background-size: 200% 200%;
    animation: AnimationBkg 4s ease infinite;

    em {
      background-image: $color-box;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      background-size: 200% 200%;
      animation: AnimationBkg 4s ease infinite;
    }

    @keyframes AnimationBkg {
      0% {
        background-position: 0% 50%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 50%;
      }
    }
  }
}

button.btn-no-style {
  background: none;
  border: none;
  cursor: initial;
}

// btn-delete
.btn-delete {
  border-radius: $radius-box;
  font-family: #{$font-text};
  font-size: #{$text-sm};
  // margin-top: $space-md;
  // margin-bottom: $space-md;
  padding: $padding-box;
  border: none;
  color: $greyUi;
  &:hover,
  &:active,
  &:focus,
  &.active {
    background-color: $red;
    em {
      color: $white;
    }
  }
}

// btn icons
.btn-icon {
  border-radius: $radius-box;
  font-family: #{$font-text};
  font-size: #{$text-sm};
  padding: $padding-box-info;
  border: $border-button;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  svg {
    margin-right: .6rem;
  }
  &:hover,
  &:active,
  &:focus,
  &.active {
    background-color: $black;
    color: $white;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
    
  }
}

// btn funnels
button.toggle-btn {
  padding: $padding-toggle;
  height: 100%;
  width: 100%;
  border-radius: $radius-card !important;
  border: 1.5px solid $black !important;
  background: none;
  font-family: #{$font-text};
  font-size: #{$text-sm};
  margin-top: $space-md;
  margin-bottom: $space-md;
  color: $black;
  font-weight: bold;
  .title-toggle-btn{
    text-transform: lowercase;
    color:$black;
    &.select,
    &:hover,
    &:hover.select {
      color: $black;
    }
  }

  &.select,
  &:hover,
  &:hover.select {
    background: $black;
    color: $white;
    .svgIcon{
      filter: invert(1)
    }
  }
}

button.toggle-btn-2 {
  text-transform: initial;
  padding: $padding-toggle;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: none;
  color: $black;
  font-weight: bold;
  border-radius: $radius-box  !important;;
  font-family: #{$font-text};
  font-size: #{$text-sm};
  margin-top: $space-md;
  margin-bottom: $space-md;
  padding: $padding-box;
  border-top-right-radius: $radius-box !important;
  border-bottom-right-radius: $radius-box !important;
  border: 1.5px solid $black !important;

  span{
    font-size:#{$text-xs};
  }
  
  &.select,
  &:hover,
  &:hover.select,
  &.Mui-selected {
    background: $black !important;
    color: $white !important;
  
    img {
      fill: $white;
      color: $white;
    }
  }
  &:first-of-type{
    border-top-right-radius: $radius-box !important;
    border-bottom-right-radius: $radius-box !important;
  }
}

.btn-funnel{
  justify-content: center;
  z-index:9999;
  position: absolute;
    align-items: center;
    display: flex;
    border: none;
    height: 40px;
    width: 40px;
    min-width: 40px;
    background-color: $black;
    border-radius: 100%;
    color: $white;
  &:hover,
  &:active,
  &:focus,
  &.active {
    background-color: #FF00B4;
    border-color: #FF00B4;
    box-shadow: none;
  }
}

.icoterms-btn {
  width: 15%;
}
.taxes-btn {
  width: 30%;
}

//btn ignorer incoterms
.btn-inco {
  border-radius: $radius-box;
  font-family: #{$font-text};
  font-size: #{$text-sm};
  margin-top: $space-md;
  margin-bottom: $space-md;
  padding: $padding-box;
  border: none;
  color: $red;
  position: absolute;
  bottom: 1rem;
  &:hover,
  &:active,
  &:focus,
  &.active {
    background-color: $grey;
  }

  em {
    color: inherit;
  }
}

// media-queries
@media screen and (max-width: $lg) {
  .btn {
    width: -webkit-fill-available;
  }

  // btn funnels
  .btn-funnel {
    bottom:1rem;
    right: 0;
    position: fixed;
  }
  .toggle-button-group{
    flex-direction: column;
  }
  button.toggle-btn-2{
    width:100%;
  }
}